import * as React from "react";
import NotFoundIcon from "@mui/icons-material/NotAccessible";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
interface INotFoundProps {}

const NotFound: React.FunctionComponent<INotFoundProps> = (props) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "60vh",
      }}
    >
      <h2
        style={{
          fontSize: "3em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NotFoundIcon sx={{ fontSize: "2em" }} />
        <span>Not Found</span>
      </h2>
      <p>
        The page you are looking for is not available.
        <Link to="/">Goto Home page</Link>
      </p>
    </Container>
  );
};

export default NotFound;
