import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../features/frontend/auth/auth-slice";

const useAuthenticated = () => {
  // is user available in redux store
  const loggedUser = useSelector(selectLoggedUser);
  // is token available in session store
  const token = sessionStorage.getItem("accesstoken");

  //   verify whether the token valid or not

  return loggedUser?._id && token ? true : false;
};

export default useAuthenticated;
