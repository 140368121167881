import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { SocialMediaLinks } from "../../features/frontend/contact/ContactDetails";
const Link = styled(NavLink)({
  textDecoration: "none",
  color: "#fff",
  lineHeight: 1.8,
});

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (
    <Box component="footer" sx={{ mt: 5 }}>
      <Box sx={{ bgcolor: "#232323", py: 3 }}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#eee", borderBottom: "2px solid #fff", mb: 2 }}
              >
                Important Links
              </Typography>
              <Link to="/about">About Us</Link>
              <Link to="/disclaimer">Disclaimer</Link>
              <Link to="/privacypolicy">Privacy Policy</Link>
              <Link to="/term-and-condition">Terms and conditions</Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#eee", borderBottom: "2px solid #fff", mb: 2 }}
              >
                Important Links
              </Typography>
              <Link to="#">Courses</Link>
              <Link to="#">Batches</Link>
              <Link to="#">Enquiry</Link>
              <Link to="#">Feedbacks</Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#eee", borderBottom: "2px solid #fff", mb: 2 }}
              >
                Follow Us
              </Typography>
              <SocialMediaLinks />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ bgcolor: "#000", p: 3 }}>
        <Typography sx={{ color: "#fff" }}>
          All rights reserved &copy; Topper Skills. {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
