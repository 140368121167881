import { lazy } from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import UserIcon from "@mui/icons-material/People";
import StudentIcon from "@mui/icons-material/People";
import BatchIcon from "@mui/icons-material/BatchPrediction";
import CourseIcon from "@mui/icons-material/Book";
import FeesIcon from "@mui/icons-material/CurrencyRupeeSharp";
import SubjectIcon from "@mui/icons-material/CastForEducation";
import FeedbackIcon from "@mui/icons-material/Feedback";
import QuestionIcon from "@mui/icons-material/QuestionAnswer";
import ExamIcon from "@mui/icons-material/QueryStats";
import PracticeIcon from "@mui/icons-material/Assignment";

const Dashboard = lazy(
  () => import("../../features/admin/dashboard/Dashboard")
);
const User = lazy(() => import("../../features/admin/user/User"));
const Student = lazy(
  () => import("../../features/admin/student/StudentModule")
);
const Batch = lazy(() => import("../../features/admin/batch/Batch"));
const Course = lazy(() => import("../../features/admin/course/Course"));
const Fees = lazy(() => import("../../features/admin/fees/Fees"));
const Subject = lazy(() => import("../../features/admin/subject/Subject"));
const Feedback = lazy(() => import("../../features/admin/feedback/Feedback"));
const Question = lazy(
  () => import("../../features/admin/question/QuestionModule")
);
const Exam = lazy(() => import("../../features/admin/exam/ExamModule"));
const StudentExam = lazy(
  () => import("../../features/admin/student-exams/StudentExams")
);
const StudentPractice = lazy(
  () => import("../../features/admin/practice/PracticeModule")
);

export default [
  {
    label: "Dashboard",
    component: <Dashboard />,
    icon: <DashboardIcon />,
    path: "dashboard",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: false,
    roles: ["superadmin", "admin", "teacher", "student"],
  },
  {
    label: "Users",
    component: <User />,
    icon: <UserIcon />,
    path: "users",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: false,

    roles: ["superadmin"],
  },
  {
    label: "Students",
    component: <Student />,
    icon: <StudentIcon />,
    path: "students",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,

    roles: ["superadmin", "admin"],
  },
  {
    label: "Subject",
    component: <Subject />,
    icon: <SubjectIcon />,
    path: "subjects",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,

    roles: ["superadmin", "admin", "teacher"],
  },
  {
    label: "Courses",
    component: <Course />,
    icon: <CourseIcon />,
    path: "courses",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: false,

    roles: ["superadmin", "admin", "teacher"],
  },
  {
    label: "Batches",
    component: <Batch />,
    icon: <BatchIcon />,
    path: "batches",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,

    roles: ["superadmin", "admin", "teacher", "student"],
  },
  {
    label: "Fees",
    component: <Fees />,
    icon: <FeesIcon />,
    path: "fees",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: false,

    roles: ["superadmin", "admin", "student"],
  },
  {
    label: "Questions",
    component: <Question />,
    icon: <QuestionIcon />,
    path: "question",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,
    roles: ["superadmin", "admin", "teacher"],
  },
  {
    label: "Exam",
    component: <Exam />,
    icon: <ExamIcon />,
    path: "exam",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,
    roles: ["superadmin", "admin", "teacher"],
  },
  {
    label: "Tests",
    component: <StudentExam />,
    icon: <ExamIcon />,
    path: "student-exams",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,
    roles: ["student"],
  },
  {
    label: "Practice",
    component: <StudentPractice />,
    icon: <PracticeIcon />,
    path: "student-practice",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: true,
    roles: ["student"],
  },
  {
    label: "Feedback",
    component: <Feedback />,
    icon: <FeedbackIcon />,
    path: "feedback",
    isAuthenticated: "yes",
    showInAdminMenu: true,
    hasChildren: false,
    roles: ["student"],
  },
];
