import * as React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import UserAccount from "@mui/icons-material/AccountCircle";
import useAuthenticated from "../../utils/hooks/useAuthenticated";
import useLogout from "../../utils/hooks/useLogout";

interface ILink {
  to: string;
  children: React.ReactElement;
  onClick?: any;
}
const Link: React.FunctionComponent<ILink> = ({ children, ...props }) => (
  <NavLink
    {...props}
    style={({ isActive }) => ({
      textDecoration: "none",
    })}
  >
    {children}
  </NavLink>
);

interface IUserProfileMenuProps {}

const UserProfileMenu: React.FunctionComponent<IUserProfileMenuProps> = (
  props
) => {
  const isAuthenticated = useAuthenticated();
  const logout = useLogout();
  const [anchorElUser, setAnchorElUser] =
    React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout();
    handleCloseUserMenu();
  };

  return (
    <Box sx={{ ml: 1, flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Topper Skills">
            <UserAccount />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleCloseUserMenu}>
          <Link to={isAuthenticated ? `/secured/dashboard` : "/login"}>
            <Typography textAlign="center">Dashboard</Typography>
          </Link>
        </MenuItem>
        <Divider />
        {isAuthenticated ? (
          <MenuItem onClick={handleCloseUserMenu}>
            <Link to="/login" onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </Link>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleCloseUserMenu}>
            <Link to="/login">
              <Typography textAlign="center">Login</Typography>
            </Link>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default UserProfileMenu;
