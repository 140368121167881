import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";

type Answer = {
  question: string;
  selectedAnswer: string;
};

const initialState: Answer[] = [];

const examSlice = createSlice({
  name: "examResult",
  initialState,
  reducers: {
    loadInitialResult: (state, { payload }) => payload,
    addResultAnswer: (state, { payload }) => {
      // replace the answer for same question if exists
      const questionIndex = state?.findIndex(
        (result) => result?.question == payload?.question
      );
      if (questionIndex != -1) {
        state?.splice(questionIndex, 1, payload);
      } else {
        state?.push(payload);
      }
    },
    resetExamResult: () => initialState,
  },
});

export const { addResultAnswer, loadInitialResult } = examSlice?.actions;
export const selectExamResult = (state: RootState) => state?.examResult;
export default examSlice?.reducer;
