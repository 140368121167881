import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import User, { defaultUser } from "../../../utils/models/user-model";

const initialState: { loggedUser: User } = {
  loggedUser: { ...defaultUser },
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      state.loggedUser = payload;
    },
    removeUser: () => initialState,
  },
});

export const { addUser, removeUser } = authSlice.actions;
export const selectLoggedUser = (state: RootState) => state?.auth?.loggedUser;
export default authSlice?.reducer;
