import { lazy } from "react";
import HomeIcon from "@mui/icons-material/Home";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CourseIcon from "@mui/icons-material/Book";
import BatchIcon from "@mui/icons-material/BatchPrediction";
import ContactIcon from "@mui/icons-material/ContactPage";
import LoginIcon from "@mui/icons-material/Login";
import EnquiryIcon from "@mui/icons-material/Help";
import DisclaimerIcon from "@mui/icons-material/Help";
import PrivacyPolicyIcon from "@mui/icons-material/Policy";
import TermAndConditionIcon from "@mui/icons-material/FactCheck";

import AboutIcon from "@mui/icons-material/Help";
const Home = lazy(() => import("../../features/frontend/home/Home"));
const TermAndCondition = lazy(
  () => import("../../features/frontend/terms-and-conditions/TermAndCondition")
);
const PrivacyPolicy = lazy(
  () => import("../../features/frontend/privacy-policy/PrivacyPolicy")
);
const Disclaimer = lazy(
  () => import("../../features/frontend/disclaimer/Disclaimer")
);
const Feedback = lazy(
  () => import("../../features/frontend/feedback-listing/FeedbackListing")
);
const Contact = lazy(() => import("../../features/frontend/contact/Contact"));
const CourseListing = lazy(
  () => import("../../features/frontend/course-listing/CourseListing")
);
const BatchListing = lazy(
  () => import("../../features/frontend/batch-listing/BatchListing")
);
const Login = lazy(() => import("../../features/frontend/auth/Login"));
const Enquiry = lazy(() => import("../../features/frontend/enquiry/Enquiry"));
const About = lazy(() => import("../../features/frontend/about/About"));

export default [
  {
    label: "Home",
    component: <Home />,
    icon: <HomeIcon />,
    path: "",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Courses",
    component: <CourseListing />,
    icon: <CourseIcon />,
    path: "courses",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
 
  {
    label: "Batches",
    component: <BatchListing />,
    icon: <BatchIcon />,
    path: "batches",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Feedbacks",
    component: <Feedback />,
    icon: <FeedbackIcon />,
    path: "feedbacks",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Enquiry",
    component: <Enquiry />,
    icon: <EnquiryIcon />,
    path: "enquiry",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Contact",
    component: <Contact />,
    icon: <ContactIcon />,
    path: "contact",
    isAuthenticated: "all",
    showInMainMenu: true,
  },
  {
    label: "Login",
    component: <Login />,
    icon: <LoginIcon />,
    path: "login",
    isAuthenticated: "no",
    showInMainMenu: false,
  },

  {
    label: "Disclaimer",
    component: <Disclaimer />,
    icon: <DisclaimerIcon />,
    path: "disclaimer",
    isAuthenticated: "no",
    showInMainMenu: false,
  },

  {
    label: "PrivacyPolicy",
    component: <PrivacyPolicy />,
    icon: <PrivacyPolicyIcon />,
    path: "privacypolicy",
    isAuthenticated: "no",
    showInMainMenu: false,
  },

  {
    label: "TermAndCondition",
    component: <TermAndCondition />,
    icon: <TermAndConditionIcon />,
    path: "term-and-condition",
    isAuthenticated: "no",
    showInMainMenu: false,
  },
  {
    label: "About Us",
    component: <About/>,
    icon: <AboutIcon />,
    path: "about",
    isAuthenticated: "all",
    showInMainMenu: false,
  },
];
