import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { NavLink } from "react-router-dom";
import SidebarRoute from "./SidebarRoute";
import frontendRoutes from "../../utils/routes/frontend-routes";
import UserProfileMenu from "../user-profile-menu/UserProfileMenu";
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

const Sidebar = (props: Props) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  //   mobile view
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src="images/logo.png"
          style={{ width: 50, marginRight: 10, marginLeft: 5 }}
        />
        <Typography variant="h6" sx={{ my: 2 }}>
          Topper Skills
        </Typography>
      </Box>
      <Divider />
      <List>
        {Array.isArray(frontendRoutes) &&
          frontendRoutes
            .filter(({ showInMainMenu }) => showInMainMenu)
            .map(({ label, path }, i) => (
              <ListItem key={i} disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <NavLink
                    to={`/${path}`}
                    style={({ isActive }) => ({
                      color: isActive ? "#666" : "#000",
                      textDecoration: "none",
                    })}
                  >
                    <ListItemText primary={label} />
                  </NavLink>
                </ListItemButton>
              </ListItem>
            ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Container maxWidth="xl" sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {/* Desktop view */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                my: 1,
              }}
            >
              <img
                src="images/logo.png"
                style={{ width: 70, marginRight: 20 }}
              />
              <Typography variant="h6" component="div">
                Topper Skills
              </Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {Array.isArray(frontendRoutes) &&
                frontendRoutes
                  .filter(({ showInMainMenu }) => showInMainMenu)
                  .map(({ label, path }, i) => (
                    <NavLink
                      key={i}
                      to={`/${path}`}
                      style={({ isActive }) => ({
                        color: isActive ? "#aaa" : "#fff",
                        textDecoration: "none",
                        margin: "0 5px",
                        padding: "10px 2px",
                      })}
                    >
                      {label}
                    </NavLink>
                  ))}
            </Box>

            {/* user profile menu  */}
            <UserProfileMenu />
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Container>
      <Box component="main" sx={{ m: "auto", width: "100%" }}>
        <Toolbar />
        <SidebarRoute />
      </Box>
    </>
  );
};

export default Sidebar;
