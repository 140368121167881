import Batch from "./batch-model";
import Exam from "./exam-model";
import Question from "./question-model";

interface Address {
  street: string;
  city: string;
  country: string;
  pincode: number;
  [key: string]: any;
}
interface UserBatch {
  batch: Batch;
  status: number;
}
export interface ITotalFees {
  _id?: string;
  total: number;
  discount: number;
  batch: string | Batch;
  status: number;
}
interface Result {
  question: string | Question;
  selectedAnswer: string;
}

export interface Test {
  test: string | Exam;
  status: number;
  date: string | Date;
  marks: number;
  result: Result[];
}

interface User {
  name: string;
  mobile: string;
  email: string;
  password: string;
  role: string;
  gender: string;
  qualification: string;
  avatar: string;
  doj: Date;
  status: Number;
  dob: Date;
  salary: Number;
  dept: string;
  description?: string;
  address: Address;
  courses?: any;
  batches?: UserBatch[];
  feesPayments?: any;
  totalFees: ITotalFees[];
  tests: Test[];
  [key: string]: any;
}

export const defaultUser = {
  name: "",
  mobile: "",
  email: "",
  password: "",
  role: "",
  gender: "",
  qualification: "",
  avatar: "",
  doj: new Date(),
  status: 0,
  dob: new Date(),
  salary: 0,
  dept: "",
  address: {
    street: "",
    city: "",
    country: "",
    pincode: 0,
  },
  courses: "",
  batches: [],
  totalFees: [],
  tests: [],
  feesPayments: "",
};

export default User;
