import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import Course from "../../../utils/models/course-model";

const initialState: Course[] = [];

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    addCourses: (state, { payload }) => payload,
    resetCourses: () => initialState,
  },
});

export const { addCourses, resetCourses } = coursesSlice?.actions;
export const selectCourses = (state: RootState) => state?.courses;
export default coursesSlice?.reducer;
