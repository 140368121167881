import * as React from "react";
import AdminPanel from "../../components/admin-panel/AdminPanel";

interface ISecureLayoutProps {}

const SecureLayout: React.FunctionComponent<ISecureLayoutProps> = (props) => {
  return (
    <>
      <AdminPanel />
    </>
  );
};

export default SecureLayout;
