import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUser } from "../../features/frontend/auth/auth-slice";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return () => {
    // remove user from redux store
    dispatch(removeUser());
    // remove token from session storage
    sessionStorage.clear();

    // navigate to login
    navigate("/login");
  };
};

export default useLogout;
