import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import Batch from "../../../utils/models/batch-model";

const initialState: Batch[] = [];

const batchesSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    addBatches: (state, { payload }) => payload,
    resetBatches: () => initialState,
  },
});

export const { addBatches, resetBatches } = batchesSlice?.actions;
export const selectBatches = (state: RootState) => state?.batches;
export default batchesSlice?.reducer;
