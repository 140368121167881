import * as React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Footer from "./Footer";

interface IBlankLayoutProps {}

const BlankLayout: React.FunctionComponent<IBlankLayoutProps> = (props) => {
  return (
    <>
      <Sidebar />
      <Footer />
    </>
  );
};

export default BlankLayout;
