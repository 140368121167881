import { isAsyncThunkAction } from "@reduxjs/toolkit";
import * as React from "react";
import { Routes, Route } from "react-router-dom";
import useAuthenticated from "../../utils/hooks/useAuthenticated";
import frontendRoutes from "../../utils/routes/frontend-routes";
import NotFound from "../not-found/NotFound";

interface ISidebarRouteProps {}

const SidebarRoute: React.FunctionComponent<ISidebarRouteProps> = (props) => {
  const isLogged = useAuthenticated();

  // const filterRoute = (isAuth: string) => {
  //   if (isAuth == "all") return true;
  //   if (isLogged && isAuth == "yes") return true;
  //   if (isLogged && isAuth == "no") return false;
  //   if (!isLogged && isAuth == "yes") return false;
  //   if (!isLogged && isAuth == "no") return true;
  // };

  return (
    <React.Suspense fallback={<h3>Loading...</h3>}>
      <Routes>
        {Array.isArray(frontendRoutes) &&
          frontendRoutes
            // ?.filter(({ isAuthenticated: isAuth }) => filterRoute(isAuth))

            ?.map(({ path, component }, i) => (
              <Route key={i} path={path} element={component} />
            ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Suspense>
  );
};

export default SidebarRoute;
