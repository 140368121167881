import * as React from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AddressIcon from "@mui/icons-material/LocationCity";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Link = styled("a")({
  textDecoration: "none",
  color: "inherit",
});

export const SocialMediaLinks = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" spacing={2}>
        <Link href="#" target="_blank">
          <Avatar sx={{ bgcolor: "#0077B5" }}>
            <LinkedInIcon />
          </Avatar>
        </Link>

        <Link href="https://maps.app.goo.gl/CRAk9pgi45Rm5LhLA" target="_blank">
          <Avatar sx={{ bgcolor: "#34A853" }}>
            <GoogleIcon />
          </Avatar>
        </Link>

        <Link href="#" target="_blank">
          <Avatar sx={{ bgcolor: "#316FF6" }}>
            <FacebookIcon />
          </Avatar>
        </Link>

        <Link href="#" target="_blank">
          <Avatar sx={{ bgcolor: "#E1306C" }}>
            <InstagramIcon />
          </Avatar>
        </Link>

        <Link href="#" target="_blank">
          <Avatar sx={{ bgcolor: "#FF0000" }}>
            <YoutubeIcon />
          </Avatar>
        </Link>
      </Stack>
    </Box>
  );
};

interface IContactDetailsProps {}

const ContactDetails: React.FunctionComponent<IContactDetailsProps> = (
  props
) => {
  return (
    <>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem>
          <ListItemAvatar>
            <Link href="tel:917875580668" target="_blank">
              <Avatar>
                <PhoneIcon />
              </Avatar>
            </Link>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link href="tel:917875580668" target="_blank">
                +91-7875580668
              </Link>
            }
            secondary={
              <Link href="tel:917875580668" target="_blank">
                Call us
              </Link>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Link
              href="https://api.whatsapp.com/send?phone=917875580668"
              target="_blank"
            >
              <Avatar>
                <WhatsAppIcon />
              </Avatar>
            </Link>
          </ListItemAvatar>

          <ListItemText
            primary={
              <Link
                href="https://api.whatsapp.com/send?phone=917875580668"
                target="_blank"
              >
                +91-7875580668
              </Link>
            }
            secondary={
              <Link
                href="https://api.whatsapp.com/send?phone=917875580668"
                target="_blank"
              >
                Chat with us
              </Link>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Link href="mailto:topperskills@gmail.com" target="_blank">
              <Avatar>
                <EmailIcon />
              </Avatar>
            </Link>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link href="mailto:topperskills@gmail.com" target="_blank">
                topperskills@gmail.com
              </Link>
            }
            secondary={
              <Link href="mailto:topperskills@gmail.com" target="_blank">
                send email
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AddressIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link
                href="https://maps.app.goo.gl/CRAk9pgi45Rm5LhLA"
                target="_blank"
              >
                A/101, Avnish Regency, Vadgaon Bu. Pune - 411041
              </Link>
            }
            secondary="office address"
          />
        </ListItem>
      </List>
      <Divider />
      <SocialMediaLinks />
    </>
  );
};

export default ContactDetails;
