import React, { ReactElement } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import BlankLayout from "./layouts/blank/BlankLayout";
import SecureLayout from "./layouts/secure/SecureLayout";

import { ToastContainer } from "react-toastify";
import useAuthenticated from "./utils/hooks/useAuthenticated";
import PageMeta from "./components/page-meta/PageMeta";

const ProtectedRoute = ({ children, ...props }: { children: ReactElement }) => {
  const isAuthenticated = useAuthenticated();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <>
      <PageMeta
        title="Topper Skills App"
        description="Topper Skills provides training and placement in Software Development"
      />

      <ToastContainer />
      <Routes>
        <Route path="/*" element={<BlankLayout />} />
        <Route
          path="secured/*"
          element={
            <ProtectedRoute>
              <SecureLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;

// npm i @mui/material @mui/icons-material @emotion/react @emotion/styled
// npm i axios react-router-dom
// npm i @reduxjs/toolkit react-redux

// Authentication vs Authorization

// Authentication = identity
// Authorization  = permission

// Authentication:-
//   it is the process of checking identity of someone

// Authorization:-
//   it is the process of checking permissions given to someone to access some sensitive part
//   of the system

//   ID card
